.headline
	color: inherit
	display: block
	+hyphensauto
	position: relative

h2.headline
	+getComponent('font-gothic')
	font-size: 6rem
	line-height: 1.233em

h3.headline
	+getComponent('font-gothic')
	font-size: 3rem
	line-height: 1.333em

h4.headline
	+getComponent('font-gothic')
	font-size: 2rem
	line-height: 1.45em

@media screen and (max-width: $mobilebp)
	h2.headline
		font-size: 2.8rem

	h3.headline
		font-size: 2.5rem

	h4.headline
		font-size: 1.6rem
a,
button
	&.btn
		background-color: $color-white
		border: none
		+borderradius(2.3rem)
		color: $color-text-dark
		cursor: pointer
		display: inline-block
		+getComponent('font-semibold')
		font-size: 1.2rem
		line-height: 1.41em
		outline: none
		padding: 1.3rem 3rem 1.5rem 3rem
		position: relative
		+transform(scale(1))
		+transition(transform .3s ease)
		+noselect
		width: auto
		@media (hover:hover)
			&:hover
				+transform(scale(1.05))
.labeled-text
	display: block
	font-size: 2.6rem
	padding: 0 .5em
	position: relative
	+noselect
	&_text
		background-color: $color-primary
		box-decoration-break: clone
		+boxshadow(-0.5em 0 0 0.03em $color-primary, 0.5em 0 0 0.03em $color-primary)
		color: $color-white
		+getComponent('font-gothic')
		line-height: 2em
		padding: 0 0 .1em 0
		position: relative

	@media screen and (max-width: $mobilebp)
		font-size: 2rem
		margin-bottom: 3rem
		&-module
			&:last-child
				.labeled-text
					margin-bottom: 0

		
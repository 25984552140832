// RESET SASS
// copyright: 2021 monodigital WBA GmbH + Co. KG

// border box-box as default box-sizing
*,
::before,
::after
	+borderbox

// responsive media
iframe,
img,
video
	height: auto
	max-width: 100%

// form elements styles match parents
input,
optgroup,
select,
textarea
	+appearance(none)
	+borderradius(0)
	padding-inline-end: 0
	padding-inline-start: 0
	touch-action: manipulation

// reset text inputs
input[type="text"]
	border: none
	background-color: 0
	+borderradius(0)
	outline: none

// reset button
button
	+borderradius(0)
	&:focus
		outline: none

// correct hidden display style
[hidden]
	display: none

// reset italic
em,
i
	font-style: italic

// reset common elements to normalize them
h1,h2,h3,h4,h5,h6, p, ul, ol, li, nav, select, address, fieldset, textarea, input
	margin: 0
	-webkit-margin-before: 0
	margin-block-start: 0
	margin-block-end: 0
	margin-inline-start: 0
	margin-inline-end: 0
	border-spacing: 0
	padding: 0
	font-style: normal

// reset ul wich are in nav containers
nav > ul
	list-style: none

// reset links
a
	&:link, &:visited, &:active, &:hover, &:active, &:focus
		color: inherit
		text-decoration: none
.contact-button
	+contact-button-color
	+borderradius(50%)
	color: $color-white
	cursor: pointer
	display: block
	height: 6.7rem
	position: relative
	+transform(scale(1))
	+transition(transform .3s ease)
	+noselect
	width: 6.7rem
	.icon
		font-size: 2.8rem
		left: 50%
		position: absolute
		top: 50%
		+transform(translate(-50%,-50%))
	@media (hover:hover)
		&:hover
			+transform(scale(1.05))

	@media screen and (max-width: $mobilebp)
		height: 4.7rem
		width: 4.7rem
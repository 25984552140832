html
	font-size: 10px
	overflow: hidden
	-webkit-overflow-scrolling: unset
	width: 100%

body
	background-color: $color-blue-dark
	font-size: 1.4rem


.wrapper
	display: block
	margin: 0 auto
	position: relative
	width: 100%

#site
	display: block
	height: 100vh
	position: relative
	width: 100%

#logo
	cursor: pointer
	display: block
	left: 5rem
	opacity: 1
	position: absolute
	top: 5rem
	+transition(opacity .3s ease)
	+noselect
	z-index: $layer-logo
	.overlay-open &
		opacity: 0
	@media screen and (max-width: $mobilebp)
		left: 2.5rem
		top: 2.5rem

#main-nav
	display: block
	opacity: 1
	pointer-events: all
	position: fixed
	right: 5rem
	top: 5rem
	+transition(opacity .3s ease)
	z-index: $layer-main-nav
	.overlay-open &,
	.loader-shown &,
	.progress-shown &
		opacity: 0
		pointer-events: none

#content
	color: $color-white
	display: block
	height: 100vh
	min-height: 100vh
	overflow: hidden
	perspective: 40vh
	position: relative
	width: 100vw
	&_contact-button
		bottom: 3rem
		display: block
		position: fixed
		right: 5rem
		z-index: $layer-bottom-buttons
	&_interaction-area
		display: block
		height: 100vh
		left: 0
		overflow: hidden
		position: fixed
		top: 0
		width: 100vw
		z-index: $layer-interaction-area
		&_scroll
			display: block
			height: 100%
			left: 0
			overflow-x: hidden
			position: absolute
			top: 0
			width: 100%
			&_duration
				display: block
				height: 100vh
				min-height: 100vh
				position: relative
			.simplebar-track
				opacity: 0
				pointer-events: none
	&_sound-button
		bottom: 3rem
		display: block
		left: 5rem
		position: fixed
		z-index: $layer-bottom-buttons
	&_scroll-down-hint
		bottom: 6rem
		color: $color-white
		display: block
		left: 50%
		opacity: 1
		pointer-events: none
		position: fixed
		+transform(translateX(-50%))
		+transition(opacity .3s ease)
		z-index: $layer-scroll-down-hint
	.overlay-open &,
	.main-menu-open &,
	.loader-shown &,
	.progress-shown &
		filter: blur(1rem)
		#content_scroll-down-hint
			opacity: 0
	@media screen and (max-width: $mobilebp)
		&_sound-button
			bottom: 2rem
			left: 2rem
		&_scroll-down-hint
			bottom: 2rem
		&_contact-button
			bottom: 2rem
			right: 2rem

.animation
	&-assets
		display: block
		height: 100%
		left: 0
		position: absolute
		top: 0
		width: 100%
		z-index: -1
	&-sheet
		display: none
		height: 100vh
		left: 0
		opacity: 1
		perspective: 40vh
		pointer-events: none
		position: absolute
		top: 0
		+transition(opacity .1s ease, transform .1s ease)
		+transform(scale3d(0,0,0))
		width: 100vw
		&.blend-element
			opacity: .1
			+transform(scale3d(.1,.1,.1))
		&.visible
			display: block

#overlays
	display: block
	left: 0
	height: 100vh
	pointer-events: none
	position: fixed
	top: 0
	width: 100%
	z-index: $layer-overlay
	&_closer
		background-color: transparent
		border: .1rem solid $color-white
		+borderradius(50%)
		color: $color-white
		cursor: pointer
		display: block
		height: 3.8rem
		opacity: 0
		position: absolute
		right: 5rem
		top: 9rem
		+transform(scale(1))
		+transition(opactiy .3s ease, transform .3s ease)
		+noselect
		width: 3.8rem
		z-index: 10
		.overlay-open &
			opacity: 1
		&::before,
		&::after
			background-color: currentColor
			content: ''
			display: block
			height: 1.2rem
			left: 50%
			position: absolute
			top: 50%
			+transform(translate(-50%,-50%) rotate(45deg))
			width: .2rem
		&::after
			+transform(translate(-50%,-50%) rotate(-45deg))
		@media (hover:hover)
			&:hover
				+transform(scale(1.1))
		&.back-button
			&::before,
			&::after
				height: 1rem
				left: calc(50% - .1rem)
			&::before
				top: calc(50% - .3rem)
			&::after
				top: calc(50% + .3rem)
			
	.overlay-open &
		pointer-events: all
	
	@media screen and (max-width: $mobilebp)
		&_closer
			right: $mobile-side-gap
			top: 4.8rem

#page
	&-loader
		display: block
		left: 50%
		height: 100vh
		min-height: 100vh
		min-width: 100vw
		pointer-events: none
		position: fixed
		top: 50%
		+transform(translate(-50%,-50%))
		width: 100vw
		z-index: $layer-page-loader
		&.page-loader-shown
			pointer-events: all
	&-progress
		background-color: $page-progress-bgrd
		display: block
		left: 0
		height: 100vh
		opacity: 0
		pointer-events: none
		position: fixed
		top: 0
		+transition(opacity .3s ease)
		width: 100vw
		z-index: $layer-page-progress
		.progress
			left: 50%
			max-width: 30rem
			position: absolute
			top: 50%
			+transform(translate(-50%,-50%))
			width: 40%
		&.page-progress-shown
			opacity: 1
			pointer-events: all

=richtext
	+getComponent('font-reg')
	font-size: 1.4rem
	line-height: 1.2em
	p
		margin: 1em 0
		&:first-child
			margin-top: 0
		&:last-child
			margin-bottom: 0
	ul
		display: block
		list-style: none
		padding-left: 4rem
		position: relative
		li
			display: block
			margin: 1.5em 0
			position: relative
			&::before
				background-color: $color-white
				+borderradius(50%)
				content: ''
				display: block
				height: .3em
				left: -2.5rem
				top: .35em
				position: absolute
				width: .3em

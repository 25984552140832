.agency-cases
	display: block
	position: relative
	&_headline
		color: $color-primary
		+getComponent('font-reg')
		font-size: 1.4rem
		line-height: 1.42em
		margin-bottom: 1rem
		position: relative
	&_cases
		display: flex
		flex-direction: row
		flex-wrap: wrap
		list-style: none
		margin: 0 -1.5rem
		position: relative
		width: calc(100% + 3rem)
		li
			display: block
			margin: 1rem 1.5rem
			position: relative
			+transform(scale(1))
			+transition(transform .3s ease)
			width: calc(33.3% - 3rem)
			@media (hover:hover)
				&:hover
					+transform(scale(1.05))

	@media screen and (max-width: $mobilebp)
		&_cases
			margin: 0
			width: 100%
			li
				margin: 1rem 0
				width: 100%
.agency-data
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	max-width: calc(#{$overlay-wrapper-max} - 6rem)
	position: relative
	width: 100%
	&_headline
		align-items: center
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		position: relative
		span
			display: block
			+getComponent('font-reg')
			font-size: 1.2rem
			line-height: 1.41em
			margin-left: .5rem
			position: relative
		.icon
			color: $color-white
			display: inline-block
			font-size: 3.1rem
	&_fact
		color: $color-primary
		display: block
		+getComponent('font-gothic')
		font-size: 2.4rem
		line-height: 1.45em
		padding-left: 3.5rem
		position: relative
	&_common
		display: block
		margin-right: 10rem
		position: relative
		width: 33%
		&_logo
			display: block
			margin-bottom: 1rem
			max-width: 24rem
			position: relative
			width: 100%
		&_headline
			color: $color-primary
			+getComponent('font-reg')
			font-size: 1.4rem
			line-height: 1.42em
			margin-bottom: 2rem
			position: relative
		&_link
			display: block
			margin-top: 2rem
			position: relative
	&_facts
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		position: relative
		width: 67%
		&_left,
		&_right
			display: block
			position: relative
		&_left
			margin-right: 10rem
			&_employees
				margin-top: 3.5rem

	@media screen and (max-width: $mobilebp)
		flex-wrap: wrap
		&_common,
		&_facts
			margin: 0
			width: 100%
		&_common
			margin-bottom: 7rem
			&_logo
				max-width: 80%
		&_facts
			flex-wrap: wrap
			&_left,
			&_right
				margin: 0
				width: 100%
			&_right
				margin-top: 3.5rem
//
// SASS FUNCTIONS
// copyright 2021 monodigital WBA GmbH + Co. KG
//


// DYNAMIC MIXIN GENERATION
// e.g. generation:
// @include setCompontent('myMixinName') 
//      display: block
//		position: relative
//
// e.g. use:
// .myClass
// 		@include getCompontent('myMixinName')
//
@mixin setComponent($componentName)
	%#{$componentName}
		@content

@mixin getComponent($componentName)
	@extend %#{$componentName}



// CLASSIC WEBFONT GENERATION
=webFont($fontFamily,$fontName,$fontWeight,$fontStyle,$fontDisplay: swap)
	font-family: $fontFamily
	src: url('' + $common_fontfolder + $fontName + '.woff2') format('woff2'), url('' + $common_fontfolder + $fontName + '.woff') format('woff')
	font-weight: $fontWeight
	font-style: $fontStyle
	font-display: $fontDisplay



// FONT FACE + CORRESPONDING MIXIN GENERATION
// e.g.
// - generate it with the makeFontFace mixin (give it an own mixin name at $mixinName e.g. "font-regular")
// - use it with the getCompontent mixin e.g. +getComponent("font-regular")
//
=makeFontMixin($fontFamily,$fontName,$fontWeight,$fontStyle,$mixinName,$alternateFamilies,$smoothFont: true)
	@font-face
		+webFont($fontFamily,$fontName,$fontWeight,$fontStyle)
	@include setComponent($mixinName)
		font-family: $fontFamily, #{$alternateFamilies}
		font-weight: $fontWeight
		font-style: $fontStyle
		@if $smoothFont
			+smoothfont
.image
	display: block
	position: relative
	img
		display: block
		height: auto
		position: relative
		width: 100%
	&.show-copyright
		&::after
			background-color: $color-black
			bottom: 0
			color: $color-white
			content: attr(data-copyright)
			display: inline-block
			+getComponent('font-reg')
			font-size: .8rem
			opacity: .5
			padding: .3em 1em
			position: absolute
			right: 0


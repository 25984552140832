.agency-disciplines
	display: block
	overflow-x: hidden
	overflow-y: visible
	position: relative
	width: 100%
	ul
		display: block
		list-style: none
		height: auto
		padding-bottom: 1.5rem
		position: relative
		text-align: center
		width: auto
		li
			color: $color-black
			display: inline
			+getComponent('font-gothic')
			font-size: 5rem
			line-height: 1.41em
			margin: 1rem 1.5rem
			position: relative
			+outlinedText(.1,rem,$color-white)
			white-space: nowrap
			width: auto
		.simplebar
			&-track
				&.simplebar-vertical
					display: none
			&-scrollbar
				&::before
					background-color: $simplebar-scrollbar-bgrd
			
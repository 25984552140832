@keyframes round-icon-button-highlight
	from
		+boxshadow(0 0 0 0 $round-icon-button-highlight, 0 0 0 0 $round-icon-button-highlight inset)
	to
		+boxshadow(0 0 3rem 0 $round-icon-button-highlight, 0 0 2rem 0 $round-icon-button-highlight inset)

@keyframes round-icon-button-highlight-mobile
	from
		+boxshadow(0 0 0 0 $round-icon-button-highlight, 0 0 0 0 $round-icon-button-highlight inset)
	to
		+boxshadow(0 0 2rem 0 $round-icon-button-highlight, 0 0 1rem 0 $round-icon-button-highlight inset)	

.round-icon-button
	border: .1rem solid $color-white
	+borderradius(50%)
	color: $color-white
	cursor: pointer
	display: inline-block
	height: 6.7rem
	position: relative
	+transform(scale(1))
	+transition(transform .3s ease)
	+noselect
	width: 6.7rem
	&_icon,
	&_switch-icon
		display: block
		font-size: 1.8rem
		left: 50%
		opacity: 1
		position: absolute
		top: 50%
		+transform(translate(-50%,-50%))
		+transition(opacity .3s ease)
	&_switch-icon
		opacity: 0
	&.highlight
		+animation(round-icon-button-highlight, 1s, infinite)
	&.active
		+boxshadow(none)
		.round-icon-button
			&_icon
				opacity: 0
			&_switch-icon
				opacity: 1
	
	@media (hover:hover)
		&:hover
			+transform(scale(1.05))

	@media screen and (max-width: $mobilebp)
		height: 4.7rem
		width: 4.7rem
		&.highlight
			+animation(round-icon-button-highlight-mobile, 1s, infinite)
		&.active
			+boxshadow(none)
.simple-richtext
	display: block
	position: relative
	&_headline
		display: block
		margin-bottom: 2.8rem
		position: relative
	&_text
		display: block
		position: relative
		+richtext
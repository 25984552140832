.animated-text-button
	&_content
		display: flex
		flex-direction: column
		height: 100%
		justify-content: center
		position: relative
		width: 100%
		&_bgrd-headline
			color: $color-white
			display: block
			+getComponent('font-gothic')
			font-size: 10vw
			margin-bottom: -.5em
			opacity: .1
			position: relative
			text-align: center
			width: 100%
		&_main
			display: block
			margin: 0 auto
			max-width: 85rem
			position: relative
			text-align: center
			width: 60%
			&_headline
				display: block
				margin-bottom: 3.5rem
				position: relative
			&_text
				+richtext
				display: block
				+getComponent('font-semibold')
				font-size: 1.8rem
				line-height: 1.444em
				margin: 0 auto
				max-width: 56rem
				position: relative
				width: 100%
			&_button
				display: block
				margin-top: 3.5rem
				opacity: 0
				position: relative
				+transition(opacity .5s ease)
	&.interactive
		z-index: $layer-interactive-sheet
		.animated-text-button
			&_content
				&_main
					&_button
						opacity: 1
						pointer-events: all

	@media screen and (max-width: $mobilebp)
		&_content
			&_bgrd-headline
				font-size: 13vw
				padding: 0 $mobile-side-gap
			&_main
				padding: 0 $mobile-side-gap
				width: 100%
				&_headline
					margin-bottom: 1.3rem
				&_text
					font-size: 1.5rem
				&_button
					margin-top: 2rem
a.link
    &, &:link, &:visited, &:active
        color: currentColor
        display: inline-block
        position: relative
        +transform(scale(1))
        +transition(transform .3s ease)
        &::after
            background-color: currentColor
            bottom: .1em
            content: ''
            display: block
            height: .05em
            left: 50%
            opacity: .66
            position: absolute
            +transform(translateX(-50%))
            +transition(width .15s ease)
            width: 100%
        @media (hover:hover)
            &:hover
                +transform(scale(1.02))
                &::after
                    width: 0
.kicker-headline
	display: block
	position: relative
	&_kicker
		color: $color-primary
		+getComponent('font-reg')
		font-size: 1.4rem
		line-height: 1.42em
		margin-bottom: 1rem
		position: relative
	&_headline
		display: block
		position: relative
		.headline
			line-height: 1.5em
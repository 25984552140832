.select
	border: .2rem solid transparent
	+borderradius(.6rem)
	background-color: $color-white
	color: $color-text-dark
	cursor: pointer
	display: inline-block
	font-size: 1.2rem
	+getComponent('font-semibold')
	height: 5.6rem
	line-height: 1.42em
	opacity: 1
	padding: 1.7rem 6.5rem 1.8rem 2.6rem
	position: relative
	+transition(color .3s ease, transform .3s ease, opacity .3s ease)
	width: auto
	&_box
		cursor: pointer
		display: block
		position: relative
		+noselect
		&_label
			color: $select-title-color
			display: inline-block
			font-size: 1.2rem
			left: 0
			position: relative
			top: 0
			+transition(font-size .3s ease, top .3s ease)
			white-space: nowrap
			z-index: 11
			.required &
				&::after
					content: '*'
					display: block
					font-size: .7em
					position: absolute
					right: -.6em
					top: -.3em
			.selected &
				font-size: 1rem
				display: block
				left: 0
				position: absolute
				top: -1.5rem
		&_title
			display: none
			position: relative
			z-index: 11
			.selected &
				display: inline-block
		.icon
			color: $color-text-dark
			font-size: 2.6rem
			opacity: .5
			position: absolute
			right: -4.5rem
			top: calc(50% - .3rem)
			+noselect
			+transform(translateY(-50%) rotate(0))
			+transition(color .3s ease, transform .3s ease)
			z-index: 11
	&_content
		background-color: transparent
		border: .2rem solid transparent
		+borderradiusbl(.6rem)
		+borderradiusbr(.6rem)
		border-top: none
		display: block
		left: -.2rem
		list-style: none
		max-height: 0
		opacity: 0
		overflow: hidden
		padding: 0 0 1.6rem 0
		position: absolute
		top: 100%
		+transformorigin(top center)
		+transition(background-color .3s ease, border-color .3s ease, max-height .2s ease, opacity .3s ease, padding .3s ease, top .3s ease)
		width: calc(100% + .4rem)
		z-index: 10
		&_option
			background-color: transparent
			color: currentColor
			display: block
			margin: 1rem 0
			padding: 1.1rem 2.8rem .9rem 2.8rem
			position: relative
			+transition(background-color .3s ease)
			&:first-child
				margin-top: 1rem
			@media (hover:hover)
				&:hover
					background-color: $color-text-dark
					color: $color-white
	&.open
		background-color: $color-white
		color: $color-text-dark
		.select
			&_box
				.icon
					+transform(translateY(-50%) rotate(180deg))
			&_content
				background-color: $color-white
				max-height: 75vh
				padding-top: 3rem
				opacity: 1
				overflow-y: auto
				top: calc(100% - 2.8rem)
	.error &,
	&.error
		&, &.open
			border-color: $color-error
			color: $color-error
			.select
				&_content
					border-color: $color-error
	@media (hover:hover)
		&:hover
			background-color: $color-white
			color: $color-text-dark
	&.disabled
		opacity: .4
		pointer-events: none

	@media screen and (max-width: $mobilebp)
		font-size: 1.6rem
		padding: 1.2rem 5rem 1rem 2.4rem
		&_box
			.icon
				font-size: 2.2rem
				right: -3.5rem
		&_content
			padding-bottom: 1rem
		&.open
			.select
				&_content
					padding-top: 2rem
					top: calc(100% - 2rem)
.animated-headline-motive
    &_content
        display: flex
        flex-direction: column
        height: 100%
        justify-content: center
        position: relative
        width: 100%
        &_motive
            display: block
            left: 50%
            position: absolute
            top: 50%
            +transform(translate(-50%,-50%))
            width: 100%
            img
                display: block
                height: auto
                position: relative
                width: 100%
            @media (orientation: portrait)
                height: 105%
                margin: auto
                width: auto
                img
                    height: 100%
                    max-width: inherit
                    width: auto
        &_text
            display: block
            left: 50%
            margin: 0 auto
            max-width: 51rem
            position: absolute
            top: 50%
            +transform(translate(-50%,-50%))
            width: 80%
            &_kicker,
            &_headline
                display: block
                position: relative
                text-align: center
                width: 100%
                span
                    background-color: $color-primary
                    +boxshadow(-0.3em 0 0 0.02em $color-primary, 0.5em 0 0 0.03em $color-primary)
                    box-decoration-break: clone
                    color: $color-white
                    +getComponent('font-gothic')
                    line-height: 1.5em
                    padding: 0 0 .1em 0
                    position: relative
            &_kicker
                font-size: 2.7rem
                margin-bottom: 1.5rem
            &_headline
                font-size: 6.6rem

    @media screen and (max-width: $mobilebp)
        &_content
            &_motive
                top: calc(50% - 6rem)
            &_text
                top: 66%
                &_kicker
                    font-size: 1.7rem
                &_headline
                    font-size: 4.2rem
.image-list
	display: block
	list-style: none
	position: relative
	li
		display: block
		margin: 3rem 0
		position: relative
		width: 100%
		&:first-child
			margin-top: 0
		&:last-child
			margin-bottom: 0
@keyframes scroll-down-animation
	from
		height: 2rem
	to
		height: 0rem

.scroll-down-hint
	color: currentColor
	display: inline-block
	font-size: 1.2rem
	opacity: .8
	position: relative
	width: auto
	&::before
		background-color: currentColor
		content: ''
		display: block
		height: 2em
		left: 50%
		position: absolute
		+transform(translateX(-50%) rotate(180deg))
		+transformorigin(top center)
		top: -1rem
		width: .1rem
	&.animated
		&::before
			+animation(scroll-down-animation, 1s, infinite)
	&_text
		&,
		&-touch
			display: block
			+getComponent('font-medium')
			text-align: center
			text-transform: uppercase
		&-touch
			display: none

	&.natural-scroll
		&::before
			+transform(translateX(-50%) rotate(0deg))
			top: -3.5rem
		.scroll-down-hint_text
			display: none
			&-touch
				display: block

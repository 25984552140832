// COMMON
$common_fontfolder:					'../../fonts/'



// COLORS
$color-black:						#000			
$color-white:						#FFF

$color-peach:						#FFB6A7
$color-peach-dark:					#D29386
$color-blue-dark:					#000a14

$color-primary:						$color-peach-dark

$color-text-light:					$color-white
$color-text-dark:					#01101B

$color-error:						#FE3B1E

$animated-cases-active-highlight:		rgba(255,255,255,.4)
$form-builder-loader-bgrd:			rgba(37,37,25,.1)
$form-builder-message-bgrd:			rgba($color-blue-dark,.8)
$main-menu-mobile-bgrd:				rgba(0,0,0,.75)
$overlay-bgrd:						rgba(0,0,0,.75)
$page-loader-bgrd:					rgba(0,0,0,.5)
$page-progress-bgrd:				rgba(0,0,0,.5)
$round-icon-button-highlight:		rgba(255,255,255,.33)
$select-title-color:				rgba($color-text-dark, .5)
$separator-line-on-dark:			rgba(255,255,255,.23)
$simplebar-scrollbar-bgrd:			rgba($color-white,.33)
$splide-indicator-bgrd:				rgba($color-blue-dark, .4)

// GRADIENTS

@mixin contact-button-color
	+radialgradient(top right, $color-peach, $color-peach, $color-peach-dark)

// LAYERING

$layer-scroll-down-hint:			7000
$layer-interaction-area:			7500
$layer-interactive-sheet:			7550
$layer-bottom-buttons:				7600
$layer-main-nav:					8000
$layer-overlay:						9500
$layer-logo:						9600
$layer-page-loader:					9750
$layer-page-progress:				9760

// MEASUREMENTS AND BREAKPOINTS

$overlay-wrapper-max:				94rem

$mobile-side-gap:					2.5rem
	
$maxwidth:							1280px
$tabletbp:							1024px
$mobilebp:							768px
$minimobilebp:						374px
$minwidth:							320px

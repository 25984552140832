.animated-background
	display: block
	height: 100vh
	left: 0
	overflow: hidden
	pointer-events: none
	position: absolute
	top: 0
	width: 100vw
	&_frame
		display: block
		height: auto
		left: 50%
		max-width: inherit
		min-width: 102.4rem
		position: absolute
		top: 50%
		+transform(translate(-50%,-50%))
		width: 120%
		@media (orientation: portrait)
			height: 120%
			min-height: 102.4rem
			width: auto

.nav-list
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	position: relative
	width: 100%
	li
		font-size: 1.2rem
		display: inline-block
		margin: 0 .8em
		position: relative
		+noselect
		a
			&, &:link, &:visited, &:active
				border: .1rem dotted transparent
				color: $color-white
				display: block
				font-style: 1em
				+getComponent('font-reg')
				line-height: 1.16em
				opacity: .5
				outline: none
				position: relative
				+transition(border-color .3s ease, opacity .3s ease)
			&:focus
				border-color: currentColor
			@media (hover:hover)
				&:hover
					opacity: .75
				
		&::after
			color: $color-white
			content: '|'
			font-size: 1.4em
			display: block
			opacity: .5
			position: absolute
			right: -.75em
			top: -.1em
		&:first-child
			margin-left: 0
		&:last-child
			margin-right: 0
			&::after
				display: none

	@media screen and (max-width: $mobilebp)
		align-items: center
		flex-direction: column
		flex-wrap: wrap
		justify-content: center
		min-height: 100vh
		li
			display: block
			font-size: 2rem
			margin: 1rem 0
			text-align: center
			width: 100%
			&::after
				display: none
.checkbox
	color: currentColor
	cursor: pointer
	display: inline-block
	font-size: 1.2rem
	+getComponent('font-reg')
	line-height: 1.16em
	margin: 1rem 0
	padding: .3rem 0 0 3rem
	position: relative
	+noselect
	a
		&, &:link, &:visited, &:active
			color: $color-primary
			display: inline-block
			+getComponent('font-semibold')
			position: relative
			&::after
				background-color: $color-primary
				bottom: 0
				content: ''
				display: block
				height: .06em
				left: 50%
				position: absolute
				+transform(translateX(-50%))
				+transition(width .3s ease)
				width: 0
		@media (hover:hover)
			&:hover
				&::after
					width: 100%
	&_box
		background-color: transparent
		border: .2rem solid currentColor
		+borderradius(.3rem)
		display: inline-block
		height: 1.8rem
		left: 0
		position: absolute
		top: 0
		width: 1.8rem
		&_checkmark
			background-color: transparent
			+borderradius(.1rem)
			display: block
			height: 100%
			left: 0
			opacity: 0
			position: absolute
			top: 0
			+transform(scale(0))
			+transition(background-color .3s ease, opacity .3s ease, transform .3s ease)
			width: 100%
			&::before,
			&::after
				background-color: currentColor
				+borderradius(.1rem)
				content: ''
				display: block
				height: .2rem
				left: 50%
				position: absolute
				+transform(translate(-50%,-50%) rotate(-45deg))
				+transition(height .1s ease, transform .3s ease, width .1s ease)
				top: 50%
				width: 0
			&::after
				+transform(translate(-50%,-50%) rotate(45deg))
	@media (hover:hover)
		&:hover
			.checkbox_box
				&_checkmark
					background-color: currentColor
					opacity: .66
					+transform(scale(1))
					&::before,
					&::after
						opacity: 0
	input[type="checkbox"]
		height: 0
		opacity: 0
		position: absolute
		width: 0
		&:checked
			& ~ .checkbox_box
				border-color: currentColor
				.checkbox_box_checkmark
					background-color: transparent
					opacity: 1
					+transform(scale(1))
					&::before,
					&::after
						opacity: 1
						width: 80%
	&.required
		&::after
			content: '*'
			display: block
			font-size: .8em
			left: calc(3rem - .6em)
			position: absolute
			top: 0
	.error &,
	&.error
		color: $color-error

	@media screen and (max-width: $mobilebp)
		font-size: 1.6rem
		padding: .8rem 0 0 5rem
		&.required
			&::after
				left: calc(5rem - .6em)
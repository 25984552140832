@keyframes page-spinner-rotation
	from
		+transform(translate(-50%,-50%) rotate(0deg))
	to
		+transform(translate(-50%,-50%) rotate(360deg))

.page-loader
	background-color: $page-loader-bgrd
	color: $color-primary
	display: block
	height: 120%
	left: -10%
	opacity: 0
	position: absolute
	pointer-events: none
	top: -10%
	width: 120%
	&_filter
		display: block
		height: 0
		overflow: hidden
		pointer-events: none
		position: absolute
		width: 0
	&_spinner
		+animation(page-spinner-rotation, .75s, infinite)
		border: .4rem solid currentColor
		border-top: .4rem solid transparent
		+borderradius(50%)
		display: block
		height: 4rem
		left: 50%
		position: absolute
		top: 50%
		width: 4rem
	&.shown,
	.page-loader-shown &
		opacity: 1
		pointer-events: all
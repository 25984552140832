@keyframes case-detail-loader-rotation
	from
		+transform(translate(-50%,-50%) rotate(0deg))
	to
		+transform(translate(-50%,-50%) rotate(360deg))
	
.case-detail
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	height: 100vh
	max-width: 100vw
	overflow: hidden
	position: relative
	width: 100%
	&-overlay
		grid-column: auto/span 2
	&_images,
	&_data
		display: block
		position: relative
		width: 50%
	&_images
		height: 100%
		left: 0
		min-height: 100vh
		top: 0
		&::before
			+animation(case-detail-loader-rotation, .75s, infinite)
			border: .4rem solid currentColor
			+borderradius(50%)
			border-top: .4rem solid transparent
			color: $color-primary
			content: ''
			display: block
			height: 4rem
			left: 50%
			position: absolute
			top: 50%
			+transform(translate(-50%,-50%))
			width: 4rem
		&::after
			color: $color-primary
			content: 'Lädt...'
			+getComponent('font-gothic')
			font-size: 1.4rem
			display: inline-block
			left: 50%
			position: absolute
			text-align: center
			top: calc(50% + 4rem)
			+transform(translate(-50%,-50%))
			width: auto
		&_image
			+backgroundcover
			background-position: top left
			background-repeat: no-repeat
			display: block
			height: 100%
			left: 0
			max-height: 100%
			overflow: hidden
			position: relative
			top: 0
			width: 100%
			.image
				left: 0
				opacity: 0
				position: absolute
				top: 0
			&.cropped
				clip-path: polygon(0 0, 50% 0, 50% 110%, 0 110%)
				position: absolute
				width: 200%
		.splide
			&,
			&__track,
			&__list
				display: block
				height: 100%
				position: relative
				width: 100%
			&__pagination
				bottom: 5rem
				position: absolute
				li
					margin: 0
				&__page
					background-color: transparent
					border: none
					cursor: pointer
					display: block
					height: 3.2rem
					outline: none
					position: relative
					width: 3.2rem
					+noselect
					&::before
						background-color: $splide-indicator-bgrd
						border: .2rem solid $color-white
						+borderradius(50%)
						content: ''
						height: 1.8rem
						left: .7rem
						position: absolute
						top: .7rem
						+transition(background-color .3s ease)
						width: 1.8rem
					&.is-active
						&::before
							background-color: $color-white
	&_data
		height: auto
		overflow-y: auto
		padding-bottom: 6rem
		&_content
			display: block
			margin-left: 10rem
			margin-right: auto
			max-width: calc(#{$overlay-wrapper-max*.5} - 6rem)
			padding-top: 16rem
			position: relative
			width: calc(#{$overlay-wrapper-max*.5} - 6rem)
			&_subline
				display: block
				position: relative
			&_description
				+richtext
				display: block
				margin-top: 3rem
				position: relative
			&_agency
				border-top: .1rem solid $separator-line-on-dark
				display: block
				margin-top: 5rem
				padding-top: 5rem
				position: relative
				&_headline
					color: $color-primary
					+getComponent('font-reg')
					font-size: 1.4rem
					line-height: 1.42em
					margin-bottom: 1rem
					position: relative
				&_logo
					display: block
					margin: 2rem 0 3rem 0
					max-width: 24rem
					position: relative

	@media screen and (max-width: $mobilebp)
		flex-wrap: wrap
		height: auto
		overflow-y: auto
		&_images,
		&_data
			margin: 0
			width: 100%
		&_images
			min-height: inherit
			position: relative
			&_image
				.image
					position: relative
				&.cropped
					clip-path: none
					position: relative
					width: 100%
			.splide
				&__pagination
					bottom: auto
					margin-top: 3rem
					position: relative
		&_data
			margin-top: 3rem
			&_content
				margin: 0
				max-width: inherit
				padding: 0 2.5rem
				width: 100%

.agency-presentation
	display: flex
	flex-direction: row
	flex-wrap: wrap
	justify-content: center
	position: relative
	width: 100%
	&-module
		&.overlay-content-module.col-1
			max-width: inherit
			overflow: hidden
			width: 100%
	&_data
		display: block
		max-width: calc(#{$overlay-wrapper-max} - 6rem)
		width: calc(#{$overlay-wrapper-max} - 6rem)
	&_disciplines
		display: block
		margin: 7rem 0
		position: relative
		width: 100%
	&_cases
		display: block
		max-width: calc(#{$overlay-wrapper-max} - 6rem)
		width: calc(#{$overlay-wrapper-max} - 6rem)

	@media screen and (max-width: $mobilebp)
		&-module
			&.overlay-content-module.col-1
				padding: 0
		&_data,
		&_disciplines,
		&_cases
			max-width: inherit
			width: 100%
		&_data,
		&_cases
			padding: 0 2.5rem
@keyframes form-builder-spinner-rotation
	from
		+transform(translate(-50%,-50%) rotate(0deg))
	to
		+transform(translate(-50%,-50%) rotate(360deg))

@keyframes form-builder-processing-suffix
	0%
		content: ''
	33%
		content: '.'
	66%
		content: '..'
	100%
		content: '...'

.form-builder
	display: block
	margin: 0 auto
	position: relative
	width: 100%
	&_content
		display: block
		position: relative
		width: 100%
		&_form
			display: flex
			flex-direction: row
			flex-wrap: wrap
			margin: 0 -1rem
			width: calc(100% + 2rem)
			.processing &,
			.message &
				-webkit-filter: blur(.5rem)
				filter: blur(.5rem)
		&_processing
			background-color: $form-builder-loader-bgrd
			+boxshadow(0 0 1rem 1rem $form-builder-loader-bgrd)
			color: $color-primary
			display: block
			height: calc(100% + 2rem)
			left: 0
			margin: -1rem
			opacity: 0
			pointer-events: none
			position: absolute
			top: 0
			+transition(opacity .3s ease)
			width: calc(100% + 2rem)
			z-index: 1
			&_spinner
				+animation(form-builder-spinner-rotation, .75s, infinite)
				border: .3rem solid currentColor
				border-top: .3rem solid transparent
				+borderradius(50%)
				display: block
				height: 4rem
				left: 50%
				position: absolute
				top: 50%
				width: 4rem
			&_text
				color: currentColor
				font-size: 2rem
				+getComponent('font-reg')
				display: table
				left: 50%
				position: absolute
				top: calc(50% + 5rem)
				+transform(translate(-50%,-50%))
				&::after
					+animation(form-builder-processing-suffix, .75s, infinite)
					content: ''	
					display: inline-block
					position: absolute
			.processing &
				opacity: 1
				pointer-events: all
		&_message
			background-color: $form-builder-message-bgrd
			+boxshadow(0 0 1rem 1rem $form-builder-message-bgrd)
			display: block
			height: calc(100% + 2rem)
			left: 0
			margin: -1rem
			opacity: 0
			pointer-events: none
			position: absolute
			top: 0
			+transition(opacity .3s ease)
			width: calc(100% + 2rem)
			z-index: 1
			&_container
				display: block
				padding: 2rem
				position: relative
				top: 50%
				+transform(translateY(-50%))
				width: 100%
				&_text
					display: block
					color: currentColor
					font-size: 1.4rem
					+getComponent('font-reg')
					line-height: 1.2em
					position: relative
					text-align: center
					.form-error &
						color: $color-error
				&_button
					color: currentColor
					display: flex
					flex-direction: row
					flex-wrap: nowrap
					justify-content: center
					padding: 2rem
					position: relative
					width: 100%
					.form-error &
						color: $color-error
			.message &
				opacity: 1
				pointer-events: all
	.form-row
		display: block
		margin: 1rem
		position: relative
		width: calc(100% - 2rem)
		.select,
		.radio-group,
		.input,
		.input input,
		.input .input_input,
		.input span.textarea
			width: 100%
		&_error
			color: $color-error
			display: none
			font-size: 1.1rem
			+getComponent('font-reg')
			line-height: 1.2em
			padding: 1rem 2rem 0 2rem
			position: relative
		&.error
			.form-row_error
				display: block


	@media screen and (max-width: $mobilebp)
		&_content
			&_processing
				&_text
					font-size: 1.8rem
			&_message
				&_container
					&_text
						font-size: 1.8rem
		.form-row
			&_error
				font-size: 1.6rem
.overlay
	background-color: $overlay-bgrd
	color: $color-white
	display: block
	height: 100%
	left: 50%
	opacity: 0
	overflow-x: hidden
	position: absolute
	top: 50%
	+transform(translate(-50%,-50%) scale(2,2))
	+transition(opacity .3s ease, transform .3s ease)
	width: 100%
	.overlay-open &:last-child
		opacity: 1
		+transform(translate(-50%,-50%) scale(1,1))
	.wrapper
		max-width: 94rem
	&_logo
		display: block
		left: 5rem
		position: absolute
		top: 5rem
		+noselect
		z-index: $layer-logo
	&_content
		align-items: stretch
		display: grid
		grid-auto-flow: dense
		grid-gap: 3rem 6rem
		grid-template-columns: repeat(2, 1fr)
		justify-content: center
		min-height: 100%
		padding: 16rem 0 8rem 0
		position: relative
		width: 100%
		z-index: -1
		&.paddingless
			padding: 0
	&-content-module
		display: block
		margin: 0 auto
		position: relative
		&.col-1
			grid-column: auto/span 2
			max-width: calc(#{$overlay-wrapper-max} - 6rem)
			width: calc(#{$overlay-wrapper-max} - 6rem)
		&.col-2
			grid-column: auto/span 1
			margin: 0
			max-width: calc(#{$overlay-wrapper-max*.5} - 6rem)
			width: calc(#{$overlay-wrapper-max*.5} - 6rem)
			&:first-child,
			&.odd
				justify-self: end
				margin-right: 0	

	@media screen and (max-width: $mobilebp)
		&_logo
			left: 2.5rem
			top: 2.5rem
			&.beta-state + .overlay_content
				margin-top: 4rem
				&.paddingless
					margin-top: 0
		&_content
			grid-gap: 3rem 0
			grid-template-columns: repeat(1, 1fr)
			padding-bottom: 16rem
			&.paddingless
				padding-bottom: 8rem
		&-content-module
			width: 100%
			&.col-1,
			&.col-2
				grid-column: auto/span 1
				max-width: inherit
				width: 100%
				&,
				&:first-child,
				&.odd
					justify-self: flex-start
					margin: 0
					padding: 0 $mobile-side-gap
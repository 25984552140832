.animated-agencies
	opacity: 0
	+transform(scale3d(0,0,0))
	+transition(opacity .3s ease, transform 1s ease)
	&.revert
		+transform(scale3d(2,2,2))
	&_content
		display: block
		height: 100%
		left: 0
		position: absolute
		top: 0
		width: 100%
	&_agency
		align-items: center
		display: flex
		flex-direction: column
		height: 100%
		justify-content: center
		left: 0
		opacity: 0
		position: absolute
		top: 0
		+transition(opacity .1s ease, transform .1s ease)
		+transform(scale3d(0,0,0))
		width: 100%
		&_content
			display: block
			height: auto
			left: 50%
			min-width: 20rem
			max-width: 45rem
			position: absolute
			text-align: center
			top: 50%
			+transform(translate(-50%,-50%))
			width: 33%
			&_button
				display: block
				margin-top: 2rem
				opacity: 0
				+transition(opacity .3s ease)
		&_assets
			display: block
			position: relative
		&.interactive
			.animated-agencies
				&_agency
					&_content
						&_button
							opacity: 1
							pointer-events: all
	&.visible
		opacity: 1
		+transform(scale3d(1,1,1))
	&.interactive
		z-index: $layer-interactive-sheet
	
	@media screen and (max-width: $mobilebp)
		&_agency
			&_content
				+transform(translate3d(-50%,-50%,0)!important)
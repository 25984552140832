.animated-cases
	&_content
		display: flex
		flex-direction: column
		height: 100%
		justify-content: center
		position: relative
		width: 100%
		&_bgrd-headline
			color: $color-white
			display: block
			+getComponent('font-gothic')
			font-size: 10vw
			margin-bottom: -.5em
			opacity: .1
			position: relative
			text-align: center
			width: 100%
		&_text
			display: block
			margin: 0 auto
			max-width: 85rem
			position: relative
			text-align: center
			width: 60%
			//z-index: 2
			&_headline
				display: block
				position: relative
			&_copy
				+richtext
				display: block
				+getComponent('font-semibold')
				font-size: 1.8rem
				line-height: 1.444em
				margin: 0 auto
				max-width: 56rem
				position: relative
				width: 100%
			&_headline + &_copy
				margin-top: 3.5rem
		&_cases
			align-items: center
			display: flex
			flex-direction: row
			flex-wrap: nowrap
			height: 100%
			left: 0
			perspective: 40vh
			position: absolute
			top: 0
			width: 100%
			li
				display: block
				left: 50%
				max-width: 40rem
				position: absolute
				width: 33%
				a
					display: block
					position: relative
					.image
						+transform(scale(1))
						+transition(transform .3s ease)
						@media (hover:hover)
							&:hover
								+transform(scale(1.05))
	&.interactive
		z-index: $layer-interactive-sheet
		&.circular-cases
			.animated-cases
				&_content
					&_cases
						li
							a
								pointer-events: all
	
	&.depth-cases
		&.visible
			opacity: 1
			+transform(scale3d(1,1,1))
		.animated-cases
			&_content
				perspective: 40vh
				&_cases
					li
						top: 50%
						&.interactive
							pointer-events: all
							a
								+boxshadow(0 0 5vh 1vh $animated-cases-active-highlight)
								@media (orientation: portrait)
									+boxshadow(0 0 4vw .8vw $animated-cases-active-highlight)
				&_bgrd-headline
					z-index: 1
				&_text
					z-index: 2

	@media screen and (max-width: $mobilebp)
		&_content
			&_bgrd-headline
				font-size: 13vw
				padding: 0 $mobile-side-gap
			&_text
				padding: 0 $mobile-side-gap
				width: 100%
				&_copy
					font-size: 1.5rem
				&_headline + &_copy
					margin-top: 1.3rem
		&.depth-cases
			.animated-cases
				&_content
					justify-content: flex-start
					perspective: 15vh
					&_bgrd-headline,
					&_text
						top: 20vh
					&_cases
						perspective: 15vh
						li
							margin: 0!important
							a
								+transform(scale(1.5))
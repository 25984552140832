@keyframes progress-message-suffix
	0%
		content: ''
	33%
		content: '.'
	66%
		content: '..'
	100%
		content: '...'
	
.progress
	display: block
	position: relative
	width: 100%
	&_filter
		display: block
		height: 0
		overflow: hidden
		pointer-events: none
		position: absolute
		width: 0
	&_bar
		border: .3rem solid $color-primary
		+borderradius(1rem)
		display: block
		height: 1.5rem
		padding: .3rem
		position: relative
		width: 100%
		&_inner
			background-color: $color-primary
			display: block
			max-width: 100%
			height: .3rem
			position: relative
			+transition(width .1s ease)
			width: 0%
	&_message
		color: $color-primary
		display: block
		+getComponent('font-gothic')
		font-size: 1.4rem
		margin-top: 2rem
		max-width: 30rem
		position: relative
		text-align: center
		text-transform: uppercase
		width: 100%
		&::after
			+animation(progress-message-suffix, .75s, infinite)
			content: ''	
			display: inline-block
			position: absolute
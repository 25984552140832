.main-nav
	display: block
	position: relative
	&_nav
		display: block
		position: relative
	&_mobile-menu
		cursor: pointer
		display: none
		height: 4.4rem
		pointer-events: none
		position: fixed
		top: 3rem
		+transform(scale(1))
		+transition(transform .3s ease)
		right: 3rem
		width: 4.4rem
		&_icon
			background-color: $color-white
			+borderradius(50%)
			display: block
			height: .5rem
			left: 50%
			position: absolute
			top: 50%
			+transform(translate(-50%,-50%))
			width: .5rem
			&::before,
			&::after
				background-color: $color-white
				+borderradius(50%)
				content: ''
				display: block
				height: .5rem
				left: 50%
				position: absolute
				top: 50%
				+transform(translate(-50%,-50%))
				+transition(height .3s ease, margin .3s ease, transform .3s ease, width .3s ease)
				width: .5rem
			&::before
				margin-left: -1rem
			&::after
				margin-left: 1rem
		@media (hover:hover)
			&:hover
				+transform(scale(1.05))
	@media screen and (max-width: $mobilebp)
		&_mobile-menu
			display: block
			pointer-events: all
			z-index: 1
			&_icon
				.menu-open &
					background-color: transparent
					height: 100%
					width: 100%
					&::before,
					&::after
						+borderradius(.25rem)
						height: .3rem
						margin: 0
						+transform(translate(-50%,-50%) rotate(45deg))
						width: 2.5rem
					&::after
						+transform(translate(-50%,-50%) rotate(-45deg))
		&_nav
			background-color: $main-menu-mobile-bgrd
			position: fixed
			left: 0
			min-height: 100vh
			top: 0
			+transform(translateY(-100%))
			+transition(transform .3s ease)
			width: 100%
			.menu-open &
				+transform(translateY(0))
.radio-group
	display: inline-block
	position: relative
	&_title
		color: currentColor
		display: block
		+getComponent('font-semibold')
		font-size: 1.2rem
		line-height: 1.42em
		margin-bottom: 1.5rem
		position: relative
		.required &
			&::before
				content: '*'
				display: inline-block
				font-size: .7em
				position: relative
				margin-right: .1em
				top: -.3em
	&_radio
		color: currentColor
		cursor: pointer
		display: block
		margin: 2rem 0
		padding-left: 3rem
		position: relative
		+noselect
		&:last-child
			margin-bottom: 0
		&.error,
		.error &
			color: $color-error
		.image
			max-width: 60%
			img
				height: auto
				width: 100%
		&_box
			background-color: transparent
			border: .2rem solid currentColor
			+borderradius(50%)
			display: block
			height: 1.8rem
			left: 0
			position: absolute
			top: 50%
			+transform(translateY(-50%))
			width: 1.8rem
			&::before,
			&::after
				background-color: currentColor
				+borderradius(50%)
				content: ''
				display: block
				height: 100%
				left: 50%
				opacity: 0
				position: absolute
				top: 50%
				+transform(translate(-50%,-50%))
				+transition(opacity .3s ease)
				width: 100%
			&::after
				height: 66%
				width: 66%
		@media (hover:hover)
			&:hover
				.radio-group_radio_box
					&::before
						opacity: .66
		input[type="radio"]
			height: 0
			opacity: 0
			position: absolute
			width: 0
			&:checked
				& ~ .radio-group_radio_box
					border-color: currentColor
					&:before
						opacity: 0
					&:after
						opacity: 1
.input
	display: inline-block
	position: relative
	label
		color: $color-text-dark
		display: block
		flex-direction: row
		flex-wrap: nowrap
		font-size: 1.2rem
		+getComponent('font-semibold')
		line-height: 1.42em
		left: 2.5rem
		max-width: calc(100% - 5rem)
		opacity: .5
		pointer-events: none
		position: absolute
		top: 1.9rem
		+transition(font-size .3s ease, top .1s ease)
		white-space: nowrap
		+noselect
		width: calc(100% - 7rem)
		span
			display: block
			opacity: .5
			overflow: hidden
			position: relative
			text-overflow: ellipsis
	&.required
		label
			span
				&::before
					content: '*'
					display: inline-block
					font-size: .7em
					position: relative
					margin-right: .1em
					top: -.3em
	input[type=file],
	input[type=email],
	input[type=number],
	input[type=password],
	input[type=text],
	.input_input,
	span.textarea
		background-color: $color-white
		border: .2rem solid transparent
		+borderradius(.6rem)
		color: $color-text-dark
		display: inline-block
		font-size: 1.2rem
		+getComponent('font-semibold')
		line-height: 1.42em
		max-width: 100%
		min-height: 5.6rem
		min-width: 25rem
		opacity: 1
		outline: none
		padding: 1.7rem 2.6rem 1.8rem 2.6rem
		position: relative
		+transition(background-color .3s ease, border-color .3s ease, color .3s ease, transform .3s ease, opacity .3s ease)
		width: auto
		&:focus,
		&.filled
			& + label,
			& + .input_input + label
				font-size: 1rem
				top: .5rem
	span.textarea
		min-height: 11.8rem
	.input_input
		cursor: pointer
		text-overflow: ellipsis
		overflow: hidden
	input[type=file],
	textarea
		display: block
		height: .01rem
		opacity: 0
		overflow: hidden
		position: absolute
		width: .01rem
		z-index: -1
	input[type=submit],
	input[type=button]
		background-color: $color-primary
		border: .2rem solid transparent
		+borderradius(.6rem)
		color: $color-text-dark
		cursor: pointer
		display: inline-block
		font-size: 1.2rem
		+getComponent('font-semibold')
		line-height: 1.42em
		max-width: 100%
		min-height: 5.6rem
		min-width: 25rem
		opacity: 1
		outline: none
		padding: 1.7rem 2.6rem 1.8rem 2.6rem
		position: relative
		+transition(transform .3s ease, opacity .3s ease)
		width: auto
		+noselect
		@media (hover:hover)
			&:hover
				+transform(scale(1.05))
	&.error,
	.error &
		input[type=email],
		input[type=number],
		input[type=password],
		input[type=text],
		.input_input,
		span.textarea
			border-color: $color-error
			color: $color-error
		label
			span
				color: $color-error
			.label-line
				background-color: $color-error

.logo
	color: $color-primary
	display: block
	position: relative
	.icon
		font-size: 12rem
	svg
		text
			+getComponent('font-gothic')
	.beta-state &
		&::before
			background-color: $color-primary
			+borderradius(2em)
			bottom: -1.6rem
			color: $color-white
			content: 'BETA'
			display: block
			font-size: 1rem
			left: 50%
			padding: .5em 1em .4em 1em
			position: absolute
			+transform(translateX(-50%))
			z-index: 1
